import { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout ,LayoutEmptyRoute,EmptyLayoutNew } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import UserPasswordRecovery from 'pages/UserPasswordRecovery/UserPasswordRecovery';
import UserOTPRequest from 'pages/UserOTPRequest/UserOTPRequest';
import UserPasswordReset from 'pages/UserPasswordReset/UserPasswordReset';
import FrontPage from 'pages/FrontPage/FrontPage';
import TestPage from 'pages/TestPage/TestPage';


import AuthPage from 'pages/AuthPage';
import PrivacyPolicy from 'pages/PrivacyPolicy/PrivacyPolicy';

import React from 'react';
import {withRouter} from "react-router";
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch, Link  } from 'react-router-dom';
//import {Params} from 'CallHook'
//BrowserRouter as Router,  Link
import './styles/reduction.scss';



const AdminDashboard = React.lazy(() => import('pages/AdminDashboard/AdminDashboard'));
const Dashboard = React.lazy(() => import('pages/Dashboard/Dashboard'));
const AdminNewTask = React.lazy(() => import('pages/AdminNewTask/AdminNewTask'));
const AdminTaskTimeline = React.lazy(() => import('pages/AdminTaskTimeline/AdminTaskTimeline'));
const AdminViewTask = React.lazy(() => import('pages/AdminViewTask/AdminViewTask'));
const AdminViewReceivedTask = React.lazy(() => import('pages/AdminViewReceivedTask/AdminViewReceivedTask'));
const AdminAddCompany = React.lazy(() => import('pages/AdminAddCompany/AdminAddCompany'));
const AdminViewCompany = React.lazy(() => import('pages/AdminViewCompany/AdminViewCompany'));
const AdminAddDivision = React.lazy(() => import('pages/AdminAddDivision/AdminAddDivision'));
const AdminViewDivision = React.lazy(() => import('pages/AdminViewDivision/AdminViewDivision'));
const AdminAddDepartment = React.lazy(() => import('pages/AdminAddDepartment/AdminAddDepartment'));
const AdminViewDepartment = React.lazy(() => import('pages/AdminViewDepartment/AdminViewDepartment'));
const AdminAddDesignation = React.lazy(() => import('pages/AdminAddDesignation/AdminAddDesignation'));
const AdminViewDesignation = React.lazy(() => import('pages/AdminViewDesignation/AdminViewDesignation'));
const AdminViewEmployee = React.lazy(() => import('pages/AdminViewEmployee/AdminViewEmployee'));
const AdminSearchPage = React.lazy(() => import('pages/AdminSearchPage/AdminSearchPage'));



const FormControls = React.lazy(() => import('pages/FormControls/FormControls'));
const EmployeeRegistration = React.lazy(() => import('pages/EmployeeRegistration/EmployeeRegistration'));
const CompanyTestPage = React.lazy(() => import('pages/CompanyTestPage/CompanyTestPage'));

const AdminReportPendingTask = React.lazy(() => import('pages/AdminReportPendingTask/AdminReportPendingTask'));
const AdminReportMyTask = React.lazy(() => import('pages/AdminReportMyTask/AdminReportMyTask'));
const AdminReportAssignTask = React.lazy(() => import('pages/AdminReportAssignTask/AdminReportAssignTask'));
const Report = React.lazy(() => import('pages/Report/Report'));
const ReportCompleted = React.lazy(() => import('pages/ReportCompleted/ReportCompleted'));
const Report1 = React.lazy(() => import('pages/Report1/Report1'));
const EmployeeRegistrationEdit = React.lazy(() => import('pages/EmployeeRegistrationEdit/EmployeeRegistrationEdit'));
const AdminEditDesignation = React.lazy(() => import('pages/AdminEditDesignation/AdminEditDesignation'));
const AdminEditDepartment = React.lazy(() => import('pages/AdminEditDepartment/AdminEditDepartment'));
const AdminEditCompany = React.lazy(() => import('pages/AdminEditCompany/AdminEditCompany'));         
const AdminEditDivision = React.lazy(() => import('pages/AdminEditDivision/AdminEditDivision')); 
const AdminSupportTicketList= React.lazy(() => import('pages/AdminSupportTicketList/AdminSupportTicketList')); 
const ViewAssignTaskToday = React.lazy(() => import('pages/ViewAssignTaskToday/ViewAssignTaskToday')); 
const ViewReceivedTaskToday = React.lazy(() => import('pages/ViewReceivedTaskToday/ViewReceivedTaskToday')); 
const ViewUserReceivedTask = React.lazy(() => import('pages/ViewUserReceivedTask/ViewUserReceivedTask')); 
const ViewUserAssignTask = React.lazy(() => import('pages/ViewUserAssignTask/ViewUserAssignTask')); 
const ViewAssignTask = React.lazy(() => import('pages/View/ViewAssignTask')); 
const ViewReceivedTask = React.lazy(() => import('pages/View/ViewReceivedTaskToday')); 
const UserDashboard = React.lazy(() => import('pages/User/Dashboard/Dashboard'));
const UserTaskList = React.lazy(() => import('pages/User/UserTaskList/UserTaskList'));
const UserTaskView = React.lazy(() => import('pages/User/UserTaskView/UserTaskView'));
const UserSupportTicketNew = React.lazy(() => import('pages/UserSupportTicketNew/UserSupportTicketNew'));
const UserSupportTicketView = React.lazy(() => import('pages/UserSupportTicketView/UserSupportTicketView'));
const AdminChangeTask = React.lazy(() => import('pages/AdminChangeTask/AdminChangeTask'));
const UserSearchPage = React.lazy(() => import('pages/UserSearchPage/UserSearchPage'));
const ReportAssignedBy = React.lazy(() => import('pages/ReportAssignedBy/ReportAssignedBy'));
const ReportCreateDate = React.lazy(() => import('pages/ReportCreateDate/ReportCreateDate'));
const ReportBelowToday = React.lazy(() => import('pages/ReportBelowToday/ReportBelowToday'));
const ReportPriorityWise = React.lazy(() => import('pages/ReportPriorityWise/ReportPriorityWise'));

const UserAdminLogin = React.lazy(() => import('pages/UserAdminLogin/UserAdminLogin'));

const AlertPage = React.lazy(() => import('pages/AlertPage'));
const AuthModalPage = React.lazy(() => import('pages/AuthModalPage'));
const BadgePage = React.lazy(() => import('pages/BadgePage'));
const ButtonGroupPage = React.lazy(() => import('pages/ButtonGroupPage'));
const ButtonPage = React.lazy(() => import('pages/ButtonPage'));
const CardPage = React.lazy(() => import('pages/CardPage'));
const ChartPage = React.lazy(() => import('pages/ChartPage'));
const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const DropdownPage = React.lazy(() => import('pages/DropdownPage'));
const FormPage = React.lazy(() => import('pages/FormPage'));
const InputGroupPage = React.lazy(() => import('pages/InputGroupPage'));
const ModalPage = React.lazy(() => import('pages/ModalPage'));
const ProgressPage = React.lazy(() => import('pages/ProgressPage'));
const TablePage = React.lazy(() => import('pages/TablePage'));
const TypographyPage = React.lazy(() => import('pages/TypographyPage'));
const WidgetPage = React.lazy(() => import('pages/WidgetPage'));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {
  render() {
    return (
      <BrowserRouter basename='/' >
        <GAListener>
        
          <Switch>
          {
            <LayoutRoute
              exact
              path="/"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_LOGIN} />
              )}
            />
          }
          { 
            <LayoutRoute
              exact
              path="/user-password-recovery"
              layout={EmptyLayout}
              component={props => (
                <UserPasswordRecovery {...props} authState={STATE_LOGIN} />
              )}
          />
          }
        
          { 
            <LayoutRoute
              exact
              path="/user-otp-request"
              layout={EmptyLayout}
              component={props => (
                <UserOTPRequest  {...props} authState={STATE_LOGIN} />
              )}
          />
          }
          { 
            <LayoutRoute
              exact
              path="/user-password-reset"
              layout={EmptyLayout}
              component={props => (
                <UserPasswordReset  {...props} authState={STATE_LOGIN} />
              )}
          />
          }
          { 
            <LayoutRoute
              exact
              path="/front-page"
              layout={EmptyLayout}
              component={props => (
                <FrontPage {...props} authState={STATE_LOGIN} />
              )}
            />
          }
          { 
            <LayoutEmptyRoute
              exact
              path="/test-page"
              layout={EmptyLayoutNew}
              component={props => (
                <TestPage {...props}  />
              )}
            />
          }

{ 
            <LayoutRoute
              exact
              path="/privacy-policy"
              layout={EmptyLayout}
              component={props => (
                <PrivacyPolicy {...props}  />
              )}
          />
          }

          



            <MainLayout breakpoint={this.props.breakpoint} >
              <React.Suspense fallback={<PageSpinner />}>
                {/*<Route exact path="/" component={DashboardPage} />*/}
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/admin-dashboard" component={AdminDashboard} />
                <Route exact path="/admin-search-page" component={AdminSearchPage} />
                <Route exact path="/admin-new-task" component={AdminNewTask} />               
                <Route exact path="/admin-task-timeline" component={AdminTaskTimeline} />   
                <Route exact path="/admin-view-task" component={AdminViewTask} /> 
                <Route exact path="/admin-received-task" component={AdminViewReceivedTask} />                     
                <Route exact path="/company-test-page" component={CompanyTestPage} />
                <Route exact path="/admin-add-company" component={AdminAddCompany} />
                <Route exact path="/admin-view-company" component={AdminViewCompany} />
                <Route exact path="/admin-add-division" component={AdminAddDivision} />
                <Route exact path="/admin-view-division" component={AdminViewDivision} />
                <Route exact path="/admin-add-department" component={AdminAddDepartment} />
                <Route exact path="/admin-add-designation" component={AdminAddDesignation} />
                <Route exact path="/admin-view-department" component={AdminAddDepartment} />
                <Route exact path="/admin-view-designation" component={AdminAddDesignation} />
                <Route exact path="/admin-view-employee" component={AdminViewEmployee} />
                <Route exact path="/employee-registration" component={EmployeeRegistration} />
                <Route exact path="/form-controls" component={FormControls} />
                <Route exact path="/user-task-list" component={UserTaskList} />
                <Route exact path="/user-task-view" component={UserTaskView} />
              
                <Route exact path="/admin-report-pending-task" component={AdminReportPendingTask} />
                <Route exact path="/admin-report-mytask" component={AdminReportMyTask} />
                <Route exact path="/admin-report-assign-task" component={AdminReportAssignTask} />                
                <Route exact path="/report" component={Report} />      
                <Route exact path="/report1" component={Report1} />   
                <Route exact path="/employee-registration-edit" component={EmployeeRegistrationEdit} />           
                <Route exact path="/admin-edit-designation" component={AdminEditDesignation} />     
                <Route exact path="/admin-edit-department" component={AdminEditDepartment} />     
                <Route exact path="/admin-edit-division" component={AdminEditDivision} /> 
                <Route exact path="/admin-edit-company" component={AdminEditCompany} /> 
                <Route exact path="/view-assign-task-today" component={ViewAssignTaskToday} /> 
                <Route exact path="/view-received-task-today" component={ViewReceivedTaskToday} /> 
                <Route exact path="/view-assign-task" component={ViewAssignTask} /> 
                <Route exact path="/view-received-task" component={ViewReceivedTask} /> 
                <Route exact path="/view-user-received-task" component={ViewUserReceivedTask} /> 
                <Route exact path="/view-user-assigned-task" component={ViewUserAssignTask} /> 
                <Route exact path="/user-support-ticket-new" component={UserSupportTicketNew} /> 
                <Route exact path="/user-support-ticket-view" component={UserSupportTicketView} /> 
                <Route exact path="/admin-support-ticket-list" component={AdminSupportTicketList} /> 
                <Route exact path="/change-task" component={AdminChangeTask} /> 
                <Route exact path="/user-search-page" component={UserSearchPage} />
                <Route exact path="/user-admin-login" component={UserAdminLogin} />
                <Route exact path="/report-assigned-by" component={ReportAssignedBy} />
                <Route exact path="/report-create-date" component={ReportCreateDate} />
                <Route exact path="/report-below-date" component={ReportBelowToday} />
                <Route exact path="/report-completed" component={ReportCompleted} />
                <Route exact path="/report-priority-wise" component={ReportPriorityWise} />


                <Route exact path="/login-modal" component={AuthModalPage} />
                <Route exact path="/buttons" component={ButtonPage} />
                <Route exact path="/cards" component={CardPage} />
                <Route exact path="/widgets" component={WidgetPage} />
                <Route exact path="/typography" component={TypographyPage} />
                <Route exact path="/alerts" component={AlertPage} />
                <Route exact path="/tables" component={TablePage} />
                <Route exact path="/badges" component={BadgePage} />
                <Route exact path="/button-groups" component={ButtonGroupPage} />
                <Route exact path="/dropdowns" component={DropdownPage} />
                <Route exact path="/progress" component={ProgressPage} />
                <Route exact path="/modals" component={ModalPage} />
                <Route exact path="/forms" component={FormPage} />
                <Route exact path="/input-groups" component={InputGroupPage} />
                <Route exact path="/charts" component={ChartPage} />
              </React.Suspense>
            </MainLayout>
            <Redirect to="/" />
          </Switch>
        </GAListener>
       
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);

